.main-appbar-container {
  background-color: white;
  box-shadow: 0 2px 2px -2px gray;
  padding: 10px 0px;
}
._logo {
  max-width: 100%;
  height: 60px;
  cursor: pointer;
}
.main-appbar-content-wraper {
  width: 90%;
  height: 65px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid black; */
}

.logo-container {
  width: 120px;
  height: 50px;
}

.nav-links-btn {
  margin-right: 10px !important;
  font-family: "Noto Sans" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: uppercase !important;
  color: #000000 !important;
}

.sign-up-btn {
  text-transform: capitalize !important;
  color: #663399 !important;
  border: 2px solid #663399 !important;
  margin-right: 10px !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-family: "Noto Sans" !important;
}

.app-bar-component-drawer-close-btn {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  cursor: pointer !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.app-bar-component-drawer-header-container {
  /* width: 100%; */
  padding: 20px 20px;
  background-color: #fff;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 1px;
}

.app-bar-component-drawer-nav-links-container {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 50px;
  margin-left: 20px;
}

.MuiDrawer-paper {
  width: 100%;
}

.app-bar-component-drawer-nav-link {
  font-family: "Noto Sans" !important;
  font-style: normal !important;
  font-weight: "bold" !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: uppercase !important;
  color: #000000 !important;
  text-decoration: none !important;
}

._drawer_btn {
  background-color: #6633990f !important;
  width: 100% !important;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-right: 10px !important;
  font-family: "Noto Sans" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: #000 !important;
  margin-top: 15px !important;
}

._drawer_login_btn {
  width: 100% !important;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-right: 10px !important;
  font-family: "Noto Sans" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: #663399 !important;
  margin-top: 15px !important;
  border: 1px solid #663399 !important;
}

._home_navbar_active_link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #663399;
  text-decoration: none;
  margin-right: 15px;
}
._home_navbar_link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  margin-right: 15px;
  cursor: pointer;
  /*new*/
  border-radius: 5px;
  padding: auto;
  
}

._home_navbar_active_drawer_link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #663399;
  text-decoration: none;
  margin-right: 15px;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
._home_navbar_drawer_link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  margin-right: 15px;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
/*hover*/
._home_navbar_link:hover
{
transition: all 1s;
    background: black;
    color: white;
    transform: scale(1,1.1);
   
    box-shadow: 0px 0px 2px 2px #800a0c;
}
._home_navbar_drawer_link:hover
{
transition: all 1s;
    background: black;
    color: white;
    transform: scale(1,1.1);
   
    box-shadow: 0px 0px 2px 2px #800a0c;
}