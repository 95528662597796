body {
  background-color: #eeeeee;
  
}

 

.mainHeader {
  width: 100%;
  height: 800px;
  background-position: bottom center;
  /* background-image: radial-gradient(circle at center,rgba(0,0,0,0.34) 0%,rgba(0,0,0,0.47) 100%),
    url('./../../assests/images/OfferHome.jpg')!important; */
  background-position: center;
  background-size: cover;
}
.main_container {
  box-shadow: 0px 0px 5px 2px rgb(238 247 246);
  font-family:'Mukta Malar' sans-serif;
  margin-top: 20px;
  padding-bottom: 140px !important;
  
}
._bannerImg {
  max-width: 100%;  
}
figure {
  margin: 0;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #841519 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.specificCategory {
  background-color: #000;
  box-shadow: 5px 5px 10px #eee;
  padding: 10px 20px;
  border-radius: 10px;
}
.specificCategoryTitle {
  font-size: 16px;
  color: #fff;
  text-align: "left";
  margin-top: 2;
  cursor: pointer;
  padding: 10px 0px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: gray !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.specificCategoryHeading {
  color: #fff;
  font-family: "Sigmar One", cursive;
  text-align: center;
}

.row2 {
  font-size: 76px;
  color: #ffffff !important;
  letter-spacing: 1px;
  line-height: 1.1em;
}

#section2 {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #342062 !important;
}
.section2Div {
  height: 209px;
  text-align: center;
  background-image: linear-gradient(
    180deg,
    rgba(51, 0, 102, 0.52) 100%,
    #330066 100%
  ) !important;
}
.fivestarspan {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.fivestarimg {
  line-height: 0;
  height: 100%;
  padding-top: 5%;
  width: 50%;
}
.BestBuyersimg {
  line-height: 0;
  max-width: 600px;
  height: 100%;
  margin-top: 8%;
  width: 50%;
}

.slick-prev:before {
  font-family: "slick";
  font-size: 30px !important;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* border: 2px solid black; */
  /* background-color: gray; */
}
.slick-next:before {
  font-family: "slick";
  font-size: 30px !important;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* border: 2px solid black; */
}
.MuiTypography-h5{
  text-align: center;
}
.slick-prev {
  left: -32px !important;
  border: 2px solid black;
}
.slick-next {
  right: -19px !important;
  border: 2px solid black;
}
@media only screen and (max-width: 980px) {
  .section1 {
    padding-top: 5.7%;
    margin-right: -589px;
    margin-bottom: -85px;
  }
  .slick-prev {
    left: -28px !important;
    border: 2px solid black;
  }
  .slick-next {
    right: -19px !important;
    border: 2px solid black;
  }
}
