/* .rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
  background-color: #eee;
  box-shadow: 0 0 0px 0 rgb(0 0 0 / 20%);
} */

.rhap_main-controls-button {
  margin: 0 3px;
  color: #000;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: red;
  border-radius: 50px;
  box-shadow: rgb(134 134 134 / 50%) 0 0 5px;
}

.rhap_progress-bar-show-download {
  background-color: #000;
}
.NowPLayingCoverImage {
  max-width: 100%;
  margin: auto;
  /*height: 300px;*/
  margin-top: 20px;
  box-shadow: 0px 0px 4px 4px rgb(253 253 253);
}
.nowPlayingDiv {
  text-align: center;
  /* display: flex;
  flex-direction: column;
  min-height: 70vh;
  justify-content: center;
  align-items: center; */
}
.songCategory {
  color: red;
  font-family:'Mukta Malar' sans-serif;
}
.songTitle {
  color: red;
  margin-top: 20px;
}
.songDes {
  color: red;
  margin-top: 5px;
  
}
.shareBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .NowPLayingCoverImage {
    max-width: 100%;
    margin: auto;
    height: auto;
  }
}
