.cardFooter {
  display: flex;
  justify-content: flex-end;
}
.cardBtn {
  box-shadow: 0px 0px 5px 2px rgb(238 247 246) !important;
  background-color: brown !important;
  
}
.cardBtn:hover {
transform: translate(2px,3px);
transition: all 1s;
background-color: rgb(10, 10, 10);
color:rgb(253, 253, 253);
background: black;
}
.coverImage {
  width: 40px;
  height: 30px;
  box-shadow: 0px 0px 2px 2px white;
}
.fileItem {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
