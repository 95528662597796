.audioList {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family:'Mukta Malar', sans-serif;;
}
.audioListImage {
  width: 50px;
  height: 40px;
}
.audioTitle {
  margin-left: 10px;
  font-weight: bold;

}
.AudioListMain {
  background-color: #fff;
  padding: 15px 10px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 12px;
  box-shadow: 1px 1px 6px 0px #eb090936;
}
.AudioListMain:hover{
  transition: all 1s;
  box-shadow: 0px 0px 5px 5px #010111ef;
}
.audioDes {
  margin-top: 5px;
  font-size: 14x;
  font-family:'Mukta Malar', sans-serif;;
}
